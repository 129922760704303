import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import Image from "next/image";
import pkg from "../../package.json";
import { useAccount } from "../../models/account";
import {
  DarkMode,
  LightMode,
  Menu as MenuIcon,
  Close,
  ChevronRight,
  Notifications,
} from "@mui/icons-material";
import { colorModeState } from "../../models/misc";
import { useRecoilState } from "recoil";
import useIsMobile from "../../utils/useIsMobile";
import Link from "next/link";
import { FC, Fragment, useState } from "react";
import { Notification } from "../../utils/getP2PNotifications";

const logos = {
  light: require("../../assets/img/logo-rect-light.png"),
  dark: require("../../assets/img/logo-rect-dark.png"),
};

const menu = [
  {
    label: "Card",
    href: "/",
  },
  {
    label: "FINA Token",
    href: "/token",
  },
  {
    label: "P2P",
    href: "/p2p",
  },
];

interface NavbarProps {
  activeTab: string;
  notifications?: Notification[];
  onNotificationClick?: (n: Notification) => void;
}

const Navbar: FC<NavbarProps> = ({
  activeTab,
  notifications,
  onNotificationClick,
}) => {
  const {
    account: { address },
    connect,
    disconnect,
  } = useAccount();
  const [colorMode, setColorMode] = useRecoilState(colorModeState);
  const isMobile = useIsMobile();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <AppBar
        sx={(theme) => ({ backgroundColor: theme.palette.background.default })}
        component="nav"
        variant="outlined"
        elevation={0}
      >
        <Container maxWidth="xl">
          <Toolbar>
            <Stack
              flexDirection="row"
              alignItems="flex-end"
              sx={{ flexGrow: 1 }}
            >
              <Image alt="logo" src={logos[colorMode]} height={30} />
              {!isMobile && (
                <>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "0.8rem" }}
                    mb={-0.75}
                    ml={1}
                  >
                    v{pkg.version}
                  </Typography>

                  <Tabs
                    value={menu.findIndex((m) => m.href === activeTab)}
                    sx={{ mb: -2, ml: 8 }}
                  >
                    {menu.map((m) => (
                      <Tab
                        key={m.href}
                        label={m.label}
                        LinkComponent={Link}
                        href={m.href}
                      />
                    ))}
                  </Tabs>
                </>
              )}
            </Stack>
            {!isMobile ? (
              <Stack direction="row" alignItems="center">
                <Button
                  onClick={address ? disconnect : connect}
                  startIcon={
                    !address ? (
                      isMobile ? (
                        <Image
                          alt="Fina"
                          src={require("../../assets/img/wallets/fina.png")}
                          width={20}
                          height={20}
                          style={{ borderRadius: 4 }}
                        />
                      ) : (
                        <Image
                          alt="Keplr"
                          src={require("../../assets/img/wallets/keplr.png")}
                          width={20}
                          height={20}
                          style={{ borderRadius: 4 }}
                        />
                      )
                    ) : undefined
                  }
                >
                  {address
                    ? address.slice(0, 8) + "......" + address.slice(-8)
                    : "Connect Wallet"}
                </Button>
                {!!notifications && (
                  <IconButton
                    sx={{ ml: 2 }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <Badge
                      badgeContent={notifications.length}
                      color="secondary"
                    >
                      <Notifications />
                    </Badge>
                  </IconButton>
                )}
                <IconButton
                  sx={{ ml: 2 }}
                  onClick={() =>
                    setColorMode((m) => (m === "light" ? "dark" : "light"))
                  }
                >
                  {colorMode === "light" ? <DarkMode /> : <LightMode />}
                </IconButton>
              </Stack>
            ) : (
              <IconButton onClick={() => setIsMobileMenuOpen(true)}>
                <MenuIcon />
              </IconButton>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Box height={64} />
      <Drawer
        anchor="top"
        open={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          px={4}
          py={1}
        >
          <Image alt="logo" src={logos[colorMode]} height={30} />
          <IconButton onClick={() => setIsMobileMenuOpen(false)}>
            <Close />
          </IconButton>
        </Stack>
        <Divider />
        <List>
          {menu.map((m) => (
            <ListItem key={m.href} disablePadding>
              <ListItemButton href={m.href} LinkComponent={Link}>
                <ListItemText sx={{ ml: 2 }} primary={m.label} />
                <ListItemIcon>
                  <ChevronRight />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() =>
                setColorMode((m) => (m === "light" ? "dark" : "light"))
              }
            >
              <ListItemText
                sx={{ ml: 2 }}
                primary={colorMode === "light" ? "Dark Mode" : "Light Mode"}
              />
              <ListItemIcon>
                {colorMode === "light" ? <DarkMode /> : <LightMode />}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
        <Button
          sx={{ mx: 4, mb: 2 }}
          size="large"
          onClick={address ? disconnect : connect}
          startIcon={
            !address ? (
              isMobile ? (
                <Image
                  alt="Fina"
                  src={require("../../assets/img/wallets/fina.png")}
                  width={20}
                  height={20}
                  style={{ borderRadius: 4 }}
                />
              ) : (
                <Image
                  alt="Keplr"
                  src={require("../../assets/img/wallets/keplr.png")}
                  width={20}
                  height={20}
                  style={{ borderRadius: 4 }}
                />
              )
            ) : undefined
          }
        >
          {address
            ? address.slice(0, 8) + "......" + address.slice(-8)
            : "Connect Wallet"}
        </Button>
        <Typography
          variant="body2"
          sx={{ fontSize: "0.8rem", mb: 2 }}
          align="center"
        >
          v{pkg.version}
        </Typography>
      </Drawer>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: "80vh",
            width: 300,
          },
        }}
      >
        {notifications?.map((n, i) => (
          <div key={n.deal?.deal_id || n.post?.post_id}>
            <MenuItem
              sx={{
                whiteSpace: "normal",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
              onClick={() => {
                onNotificationClick?.(n);
                setAnchorEl(null);
              }}
            >
              <Typography variant="body1" fontWeight="bold" gutterBottom>
                Trade ID: {n.post.post_id}
              </Typography>
              <Typography variant="body2">{n.message}</Typography>
            </MenuItem>
            {i !== notifications?.length - 1 && <Divider />}
          </div>
        ))}
      </Menu>
    </>
  );
};

export default Navbar;
